import * as React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { WalletConnect } from './../WalletConnect';
import { Stats } from './Stats';

export const PreConnect = ({ totalSupply, maxSupply }) => {
  return (
    <Stack alignItems="center" justifyContent="center" spacing={4}>
      <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
        Mint Your Trippy Sailor NFT ⛵
      </Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
        Join the Trippy Sailors Adventure Club by minting your access pass to
        adventure! A unique travel club where NFTs unlock real sailing trips to
        the Caribbean seas, travel discounts and participation in our upcoming
        SailBNB DAO.
      </Typography>
      <WalletConnect hideOnConnected={true} />
    </Stack>
  );
};
