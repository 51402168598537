import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ImageRow } from './ImageRow';
import SailingWheel from './../../assets/images/sailing-wheel.png';

export const Dao = () => {
  return (
    <Container
      id="dao"
      maxWidth="xl"
      sx={{
        overflowX: 'clip',
        overflowY: 'visible',
        paddingTop: '72px',
        paddingBottom: '72px',
        position: 'relative',
        background: '#F7FEFF',
      }}
    >
      <Stack spacing={4} alignItems="center">
        <Typography
          variant="h3"
          sx={{ fontWeight: 'bold', textAlign: 'center' }}
        >
          Be a part of the SailBNB DAO
        </Typography>
        <Typography variant="h6" sx={{ maxWidth: 'md', textAlign: 'center' }}>
          Think AirBNB, but for yachts. As a Trippy Sailor holder, you can
          participate in SailBNB, an investing DAO. SailBNB DAO will own several
          catamarans around the world that will be chartered. The profits will
          be redistributed to the DAO. Members will get to vote on the
          allocation of funds, geographic locations, as well as future time
          share allocations for the Trippy Sailors community.
        </Typography>
        <ImageRow />
      </Stack>
      <Box
        component="img"
        src={SailingWheel}
        sx={{
          width: {
            xs: '50px',
            sm: '80px',
            md: '100px',
            lg: '183px',
            xl: '183px',
          },
          position: 'absolute',
          right: 0,
          top: {
            xs: -25,
            sm: -50,
            md: -90,
            lg: -140,
            xl: -140,
          },
        }}
      />
    </Container>
  );
};
