import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import CarnivalParrotPirate from './../../assets/images/carnival-parrot-pirate.png';
import ChadDragonPirate from './../../assets/images/chad-dragon-pirate.png';
import TrippyParrotPirate from './../../assets/images/trippy-parrot-pirate.png';
import BaldAlienPirate from './../../assets/images/bald-alien-pirate.png';
import BlueAlienSailor from './../../assets/images/blue-alien-sailor.png';
import AfroModernLizard from './../../assets/images/afro-modern-lizard.png';
import TrippyCatPirate from './../../assets/images/trippy-cat-pirate.png';
import TrippyEthPirate from './../../assets/images/trippy-eth-pirate.png';
import ModernLizardPirate from './../../assets/images/modern-lizard-pirate.png';
import BaldParrotPirate from './../../assets/images/bald-parrot-pirate.png';
import SmokingAlienPirate from './../../assets/images/smoking-alien-pirate.png';
import MonkeySailorPirate from './../../assets/images/monkey-sailor-pirate.png';
import AfroCrabPirate from './../../assets/images/afro-crab-pirate.png';

const itemsFirstRow = [
  {
    img: CarnivalParrotPirate,
    title: 'Carnival Parrot',
  },
  {
    img: ChadDragonPirate,
    title: 'Chad Dragon',
  },
  {
    img: TrippyParrotPirate,
    title: 'Trippy Parrot',
  },
  {
    img: BaldAlienPirate,
    title: 'Bald Alien',
  },

  {
    img: BlueAlienSailor,
    title: 'Blue Alien',
  },
  {
    img: AfroModernLizard,
    title: 'Afro Modern',
  },
  {
    img: TrippyCatPirate,
    title: 'Trippy Cat',
  },
];

const itemsSecondRow = [
  {
    img: TrippyEthPirate,
    title: 'Trippy Eth',
  },
  {
    img: ModernLizardPirate,
    title: 'Modern Lizard',
  },
  {
    img: BaldParrotPirate,
    title: 'Bald Parrot',
  },
  {
    img: SmokingAlienPirate,
    title: 'Smoking Alien',
  },
  {
    img: MonkeySailorPirate,
    title: 'Monkey Sailor',
  },
  {
    img: AfroCrabPirate,
    title: 'Afro Crab',
  },
];

export const ImageRow = () => {
  const imagesFirstRow = itemDataFirstRow.map((item, index) => (
    <ImageListItem key={index}>
      <img
        src={`${item.img}`}
        srcSet={`${item.img}`}
        alt={item.title}
        loading="lazy"
        style={{ borderRadius: 8, height: 165, width: 165 }}
      />
    </ImageListItem>
  ));
  const imagesSecondRow = itemDataSecondRow.map((item, index) => (
    <ImageListItem key={index}>
      <img
        src={`${item.img}`}
        srcSet={`${item.img}`}
        alt={item.title}
        loading="lazy"
        style={{ borderRadius: 8, height: 165, width: 165 }}
      />
    </ImageListItem>
  ));
  return (
    <div>
      <ImageList
        sx={{
          width: 2340,
          height: 165,
          position: 'relative',
          left: 10,
          overflowY: 'hidden',
        }}
        gap={30}
        cols={12}
        rowHeight={165}
      >
        {imagesFirstRow}
      </ImageList>
      <ImageList
        sx={{
          width: 2340,
          height: 165,
          position: 'relative',
          left: 288,
          overflowY: 'hidden',
        }}
        gap={30}
        cols={12}
        rowHeight={165}
      >
        {imagesSecondRow}
      </ImageList>
    </div>
  );
};

const itemDataFirstRow = [...itemsFirstRow, ...itemsFirstRow];
const itemDataSecondRow = [...itemsSecondRow, ...itemsSecondRow];
