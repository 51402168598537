import { useCall } from '@usedapp/core';
import { tokenAddress, contract } from '../contract';

export const usePresaleMintCount = (account) => {
  const { value, error } =
    useCall(
      tokenAddress && {
        contract,
        method: 'presaleMintCountForAddress',
        args: [account],
      }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  }
  return value?.[0].toNumber();
};
