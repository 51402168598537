import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { network } from './../../contract';
import Button from '@mui/material/Button';
import { useEthers } from '@usedapp/core';

export const SwitchNetwork = () => {
  const { switchNetwork } = useEthers();
  return (
    <Stack alignItems="center" justifyContent="center" spacing={4}>
      <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
        Trippy Sailors Are Only Available On {network.chainName}
      </Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
        Switch to {network.chainName} to continue
      </Typography>
      <Button
        variant="outlined"
        onClick={() => {
          switchNetwork(network.chainId);
        }}
      >
        Switch To {network.chainName}
      </Button>
    </Stack>
  );
};
