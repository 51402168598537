import React from 'react';
import { Intro } from './../Intro';
import { Collection } from './../Collection';
import { Perks } from './../Perks';
import { Dao } from './../Dao';
import { Roadmap } from './../Roadmap';
import { Team } from './../Team';
import { Faq } from './../Faq';
import { Footer } from './../Footer';

export const Static = () => {
  return (
    <>
      <Intro />
      <Collection />
      <Perks />
      <Dao />
      <Roadmap />
      <Team />
      <Faq />
      <Footer />
    </>
  );
};
