import React from 'react';
import Grid from '@mui/material/Grid';
import { utils } from 'ethers';
import { Stat } from './Stat';

export const PriceStats = ({
  totalSupply,
  maxSupply,
  cost,
  presale,
  presaleCost,
  presaleMaxSupply,
  presaleTotalSupply,
}) => {
  const currentCost = presale ? presaleCost : cost;
  const parsedCost = utils.formatEther(currentCost);
  let subtitleCrossed;
  if (presale) {
    subtitleCrossed = utils.formatEther(cost);
  }
  let leftToMint = maxSupply - totalSupply;
  if (presale) {
    leftToMint = Math.min(leftToMint, presaleMaxSupply - presaleTotalSupply);
  }
  return (
    <Grid
      container
      spacing={4}
      sx={{ padding: 0, maxWidth: 'sm' }}
      justifyContent="center"
      alignItems="center"
    >
      {/* {!presale && (
        <Grid item xs={6}>
          <Stat
            title={`${presale ? 'Presale' : ''} NFTs Left To Mint`}
            subtitle={leftToMint}
            compact={true}
          />
        </Grid>
      )} */}
      <Grid item xs={6}>
        <Stat
          title="Price Per NFT"
          subtitleCrossed={subtitleCrossed}
          subtitle={`${parsedCost} ETH`}
          compact={true}
        />
      </Grid>
    </Grid>
  );
};
