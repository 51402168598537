import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import AppLogo from './../../assets/images/app-logo.png';
import Tropical from './../../assets/images/tropical.png';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
// import { Link as RouterLink } from 'react-router-dom';

export const Intro = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        paddingTop: '16px',
        paddingBottom: '200px',
        background: `url(${Tropical}) no-repeat center center fixed`,
        backgroundSize: 'cover',
      }}
    >
      <Container maxWidth="lg" sx={{ padding: '32px' }}>
        <Grid container spacing={0} justifyContent="center" alignItems="center">
          <Grid item s={3} m={2}>
            <Link
              href="#collection"
              underline="none"
              color="black"
              variant="body1"
              sx={{ fontWeight: 'bold' }}
            >
              The Collection
            </Link>
          </Grid>
          <Grid item s={3} m={2}>
            <Link
              href="#perks"
              underline="none"
              color="black"
              variant="body1"
              sx={{ fontWeight: 'bold' }}
            >
              TSAC
            </Link>
          </Grid>
          <Grid item s={3} m={2}>
            <Link
              href="#dao"
              underline="none"
              color="black"
              variant="body1"
              sx={{ fontWeight: 'bold' }}
            >
              SailBNB
            </Link>
          </Grid>
          <Grid item s={3} m={2}>
            <Link
              href="#roadmap"
              underline="none"
              color="black"
              variant="body1"
              sx={{ fontWeight: 'bold' }}
            >
              Roadmap
            </Link>
          </Grid>
          <Grid item s={3} m={2}>
            <Link
              href="#team"
              underline="none"
              color="black"
              variant="body1"
              sx={{ fontWeight: 'bold' }}
            >
              Team
            </Link>
          </Grid>
          <Grid item s={3} m={2}>
            <Link
              href="/mint"
              underline="none"
              color="black"
              variant="body1"
              sx={{ fontWeight: 'bold' }}
            >
              Mint
            </Link>
          </Grid>
        </Grid>
      </Container>
      <Stack spacing={4} alignItems="center">
        <Box
          component="img"
          src={AppLogo}
          sx={{
            width: '346px',
          }}
        />
        <Typography
          variant="h3"
          sx={{ fontWeight: 'bold', textAlign: 'center' }}
        >
          Trippy Sailors Adventure Club
        </Typography>
        <Typography variant="h6" sx={{ maxWidth: 'md', textAlign: 'center' }}>
          Where Your NFTs Will Make You Travel
        </Typography>
        {/* <WalletConnect /> */}
        {/* <Fab
          component={RouterLink}
          to="mint"
          color="error"
          variant="extended"
          sx={{ minWidth: '360px' }}
        >
          Mint Now
        </Fab> */}
        <Fab
          href="https://32dlevhilp2.typeform.com/to/g7LITwGp"
          target="_blank"
          color="error"
          variant="extended"
          sx={{ minWidth: '360px' }}
        >
          ⛵ Join Our Waitlist
        </Fab>
      </Stack>
    </Container>
  );
};
