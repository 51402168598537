import * as React from 'react';
import Grid from '@mui/material/Grid';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import BlueSailor from './../../assets/images/blue-robe-sailor.png';
import WhiteWren from './../../assets/images/white-robe-wren.png';
import PurpleSailor from './../../assets/images/purple-robe-sailor.png';
import WhiteSailor from './../../assets/images/white-robe-sailor.png';
import GreyShirtPirate from './../../assets/images/grey-shirt-pirate.png';
import VicarPirate from './../../assets/images/vicar-pirate.png';
import Typography from '@mui/material/Typography';

export const ImageRow = () => {
  const images = itemData.map((item, index) => (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={2}
      key={index}
      justifyContent="center"
      alignItems="center"
      sx={{ display: 'flex' }}
    >
      <ImageListItem>
        <img
          src={`${item.img}`}
          srcSet={`${item.img}`}
          alt={item.title}
          loading="lazy"
          style={{ borderRadius: 20, height: 200, width: 200 }}
        />
        <ImageListItemBar
          title={item.title}
          subtitle={
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              {item.subtitle}
            </Typography>
          }
          position="below"
        />
      </ImageListItem>
    </Grid>
  ));
  return (
    <div>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        {images}
      </Grid>
    </div>
  );
};

const items = [
  {
    img: WhiteWren,
    title: 'Karla Vilhelem',
    subtitle: 'Captain CMO',
  },
  {
    img: BlueSailor,
    title: 'Ian Johnson',
    subtitle: 'Navigator COO',
  },
  {
    img: PurpleSailor,
    title: 'Raj',
    subtitle: 'Chief Dev',
  },
  {
    img: WhiteSailor,
    title: 'Leandro Bravo',
    subtitle: 'Sailor Designer',
  },
  {
    img: GreyShirtPirate,
    title: 'Seb',
    subtitle: 'Pirate Dev',
  },
  {
    img: VicarPirate,
    title: 'Antoine De Vuyst',
    subtitle: 'Crew Mate Advisor',
  },
];

const itemData = [...items];
