import React, { useState } from 'react';
import Fab from '@mui/material/Fab';
import { useEthers } from '@usedapp/core';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import truncateEthAddress from './truncateEthAddress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Logout from '@mui/icons-material/Logout';
import Link from '@mui/material/Link';

export const WalletConnect = ({ hideOnConnected, hideOnDisconnected }) => {
  const { account, activateBrowserWallet, deactivate } = useEthers();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {!account && hideOnDisconnected !== true && (
        <Fab
          disableRipple
          disableFocusRipple
          color="warning"
          variant="extended"
          onClick={() => activateBrowserWallet()}
        >
          Connect Wallet
        </Fab>
      )}
      {account && hideOnConnected !== true && (
        <Fab
          disableRipple
          disableFocusRipple
          color="warning"
          variant="extended"
          onClick={handleClick}
          size="small"
        >
          <AccountCircleIcon sx={{ marginRight: 1 }} />
          <Typography sx={{}}>{truncateEthAddress(account)}</Typography>
        </Fab>
      )}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {/* <Link
          href="https://portfolio.metamask.io/?tab=nfts"
          target="_blank"
          rel="noopener"
          underline="none"
          color="inherit"
        >
          <MenuItem>
            <ListItemIcon>
              <OpenInNewIcon fontSize="small" />
            </ListItemIcon>
            View Portfolio
          </MenuItem>
        </Link> */}
        <MenuItem
          onClick={() => {
            handleClose();
            deactivate();
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Disconnect
        </MenuItem>
      </Menu>
    </>
  );
};
