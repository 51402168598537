import contractBuild from "@trippy-sailors/smart-contracts/TrippySailorNFT.sol/TrippySailorNFT.json";
import { utils } from "ethers";
import { Contract } from "@ethersproject/contracts";
import { Mainnet } from "@usedapp/core";

export const network = Mainnet;
export const rpcProvider =
  "https://mainnet.infura.io/v3/91f9cfc2a03f449ea4a8f8b35b7a3f80";
export const tokenAddress = "0xDc00AEBf69e4322350Ab84925Ca1756c6757Fa8c";
export const trippySailorInterface = new utils.Interface(contractBuild.abi);
export const contract = new Contract(tokenAddress, trippySailorInterface);
export const adminPanel =
  "https://etherscan.io/address/0xDc00AEBf69e4322350Ab84925Ca1756c6757Fa8c#readContract";
export const openseaLink = "https://opensea.io/collection/trippy-sailor";
