import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ImageRow } from './ImageRow';
import Clap from './../../assets/images/clap.png';

export const Team = () => {
  return (
    <Container
      id="team"
      maxWidth="xl"
      sx={{
        overflowX: 'clip',
        overflowY: 'visible',
        paddingTop: '72px',
        paddingBottom: '72px',
        position: 'relative',
        background: '#F7FEFF',
      }}
    >
      <Stack spacing={4} alignItems="center">
        <Typography
          variant="h3"
          sx={{ fontWeight: 'bold', textAlign: 'center' }}
        >
          We’re all just trippin’
        </Typography>
        <Typography variant="h6" sx={{ maxWidth: 'md', textAlign: 'center' }}>
          A group of fun-loving seasoned adventurers, we’ve all been cruising
          around the block for a while now. And the crypto winter makes us want
          to escape at warmer seas. So here’s to building fun NFT utility
          because we can! ARRRighty!
        </Typography>
        <ImageRow />
      </Stack>
      <Box
        component="img"
        src={Clap}
        sx={{
          width: {
            xs: '50px',
            sm: '80px',
            md: '100px',
            lg: '190px',
            xl: '220px',
          },
          position: 'absolute',
          right: 0,
          top: {
            xl: -120,
            lg: -90,
            md: -50,
            sm: -40,
            xs: -25,
          },
        }}
      />
    </Container>
  );
};
