import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Catamaran1 from './../../assets/images/catamaran-1.png';
import Catamaran2 from './../../assets/images/catamaran-2.png';
import Catamaran3 from './../../assets/images/catamaran-3.png';

export const ImageRow = () => {
  const images = itemData.map((item, index) => (
    <ImageListItem key={index}>
      <img
        src={`${item.img}`}
        srcSet={`${item.img}`}
        alt={item.title}
        loading="lazy"
        style={{ borderRadius: 8, height: 217, width: 360 }}
      />
    </ImageListItem>
  ));
  return (
    <div>
      <ImageList
        sx={{ width: 3510, height: 217, position: 'relative', left: -15 }}
        gap={30}
        cols={9}
        rowHeight={217}
      >
        {images}
      </ImageList>
    </div>
  );
};

const items = [
  {
    img: Catamaran1,
    title: 'Catamaran',
  },
  {
    img: Catamaran2,
    title: 'Catamaran',
  },
  {
    img: Catamaran3,
    title: 'Catamaran',
  },
];

const itemData = [...items, ...items, ...items];
