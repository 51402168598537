import * as React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import PirateHat from "./../../assets/images/pirate-hat.png";
import { Question } from "./Question";

export const Faq = () => {
  return (
    <Container
      id="faq"
      maxWidth="xl"
      sx={{
        overflowX: "clip",
        overflowY: "visible",
        paddingTop: "72px",
        paddingBottom: "72px",
        position: "relative",
        background: "#95F1FC",
      }}
    >
      <Box
        component="img"
        src={PirateHat}
        sx={{
          width: {
            xs: "50px",
            sm: "80px",
            md: "100px",
            lg: "190px",
            xl: "200px",
          },
          position: "absolute",
          left: 0,
          top: {
            xl: -120,
            lg: -90,
            md: -50,
            sm: -40,
            xs: -25,
          },
        }}
      />
      <Container maxWidth="md">
        <Stack spacing={1} alignItems="center">
          <Typography
            variant="h3"
            sx={{ fontWeight: "bold", textAlign: "center", marginBottom: 6 }}
          >
            FAQ
          </Typography>
          <Question
            question="What is the utility of Trippy Sailors Adventure Club?"
            answer="The Trippy Sailors Adventure Club is an NFT collection with real-life perks. It gives holders the opportunity to qualify for sailing trips in the Caribbean Seas on a state-of-the-art catamaran, connect with other thrill-seekers from around the world, and access future perks like the SailBNB DAO, NFT events and metaverse activations."
          />
          <Question
            question="Who Can Qualify For A Sailing Trip?"
            answer="First, all 5555 NFTs in the collection need to be minted in order to unlock the perks. In total 555 Trippy Sailors will be selected through contests, engagement and challenges on a weekly basis to qualify for the trip. NFT owners will need to KYC and their NFTs will be marked as used once they win the raffle and decide to redeem. Trips will happen over the course of four years and each vacation will consist of 5 Trippy Sailors and their plus one, a captain and a chef."
          />
          <Question
            question="What is a Legendary Sailor?"
            answer="Legendary Sailors are ultra rare NFTs that automatically qualify for the sailing trip once the entire TSAC collection has been minted. We will reveal the lucky traits after the sale!"
          />
          <Question
            question="What is the collection’s NFT supply and price?"
            answer="The Trippy Sailors Adventure club consists of 5555 unique algorithmically generated NFTs, representing sailors, pirates and free spirit adventurers, minted on the Ethereum Blockchain. The mint price is the equivalent of $420 in Ethereum at the moment of the sale. All whitelist spots will get a discount."
          />
          <Question
            question="How do I mint my NFT?"
            answer="Download the metamask.io extension for your browser or mobile app. Purchase Ethereum through the Metamask Wallet using Wyre or Send Ethereum from an exchange like Coinbase. Make sure there is enough to cover for additional gas fees. Click on Mint Now and connect your Metamask by logging in. Once joined, you will be able to purchase the NFTs in the mint section. You will be prompted to sign your transaction. Once you have made your purchase, your Trippy Sailor NFTs will be viewable in your wallet and on OpenSea."
          />
          <Question
            question="What is the SailBNB DAO?"
            answer="SailBNB DAO is an investment DAO that will be announced within the next year. Think AirBNB, but for yachts. As a Trippy Sailor holder, you can participate in SailBNB, an investing DAO. SailBNB DAO will own several catamarans around the world that will be chartered. The profits will be redistributed to the DAO. Members will get to vote on the allocation of funds, geographic locations, as well as future time share allocations for the Trippy Sailors community."
          />
        </Stack>
      </Container>
    </Container>
  );
};
