import { useCall } from '@usedapp/core';
import { tokenAddress, contract } from '../contract';

export const useMaxSupply = () => {
  const { value, error } =
    useCall(
      tokenAddress && {
        contract,
        method: 'maxSupply',
        args: [],
      }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  }
  const maxSupply = value?.[0].toNumber();
  return maxSupply;
};
