import React, { useEffect } from 'react';
import { useNotifications } from '@usedapp/core';
import Snackbar from '@mui/material/Snackbar';

export const Notifications = () => {
  const { notifications } = useNotifications();
  const [open, setOpen] = React.useState(false);
  let message = '';
  let submittedAt;
  if (notifications && notifications.length > 0) {
    const type = notifications[0].type;
    if (type === 'walletConnected') {
      message = 'Wallet Connected';
    } else if (type === 'transactionStarted') {
      message = 'Transaction Started';
    } else if (type === 'transactionSucceed') {
      message = 'Transaction Succeeded';
    } else if (type === 'transactionFailed') {
      message = 'Transaction Failed';
    }
    submittedAt = notifications[0].submittedAt;
  }
  useEffect(() => {
    if (submittedAt) {
      setOpen(true);
    }
  }, [submittedAt]);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      message={message}
    />
  );
};
