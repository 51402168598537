import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';
import { DAppProvider } from '@usedapp/core';
import { Static } from './components/Static';
import { Mint } from './components/Mint';
import { Admin } from './components/Admin';
import { Routes, Route } from 'react-router-dom';
import { Notifications } from './components/Notifications';
import { network, rpcProvider } from './contract';

const config = {
  readOnlyChainId: network.chainId,
  readOnlyUrls: {
    [network.chainId]: rpcProvider,
  },
};

function App() {
  return (
    <div className="App">
      <DAppProvider config={config}>
        <Notifications />
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/admin" element={<Admin />} />
            <Route path="/mint" element={<Mint />} />
            <Route path="/" element={<Static />} />
          </Routes>
        </ThemeProvider>
      </DAppProvider>
    </div>
  );
}

export default App;
