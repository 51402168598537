import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TrippySailorsMintSample from './../../assets/images/trippy-sailors-mint-sample.gif';
import { Header } from './Header';
import { useEtherBalance, useEthers } from '@usedapp/core';
import { useTotalSupply } from '../../hooks/useTotalSupply';
import { useMaxSupply } from '../../hooks/useMaxSupply';
import { usePresale } from '../../hooks/usePresale';
import { useMaxMintInWallet } from '../../hooks/useMaxMintInWallet';
import { Loader } from './Loader';
import { SoldOut } from './SoldOut';
import { PreConnect } from './PreConnect';
import { Sale } from './Sale';
import { usePresaleCost } from '../../hooks/usePresaleCost';
import { usePaused } from '../../hooks/usePaused';
import { usePresaleTokenPerAddressLimit } from '../../hooks/usePresaleTokenPerAddressLimit';
import { usePresaleTotalSupply } from '../../hooks/usePresaleTotalSupply';
import { usePresaleMaxSupply } from '../../hooks/usePresaleMaxSupply';
import { usePresaleMintCount } from '../../hooks/usePresaleMintCount';
import { useCost } from '../../hooks/useCost';
import { useOwner } from './../../hooks/useOwner';
import { useIsApprovedForPresale } from '../../hooks/useIsApprovedForPresale';
import { network } from './../../contract';
import { SwitchNetwork } from './SwitchNetwork';
import { Paused } from './Paused';

export const Mint = () => {
  const { account, chainId } = useEthers();
  const totalSupply = useTotalSupply();
  const maxSupply = useMaxSupply();
  const presale = usePresale();
  const cost = useCost();
  const presaleCost = usePresaleCost();
  const owner = useOwner();
  const etherBalance = useEtherBalance(account);
  const maxMintInWallet = useMaxMintInWallet();
  const isApprovedForPresale = useIsApprovedForPresale(account);
  const paused = usePaused();
  const presaleMintCount = usePresaleMintCount(account);
  const presaleTokenPerAddressLimit = usePresaleTokenPerAddressLimit();
  const presaleTotalSupply = usePresaleTotalSupply();
  const presaleMaxSupply = usePresaleMaxSupply();
  let loading = false;
  let connected = false;
  let soldOut = false;
  let isOwner = false;
  let isCorrectNetwork = chainId && chainId === network.chainId;
  if (
    typeof totalSupply !== 'number' ||
    typeof maxSupply !== 'number' ||
    typeof presale !== 'boolean' ||
    typeof etherBalance !== 'object' ||
    typeof maxMintInWallet !== 'number' ||
    typeof isApprovedForPresale !== 'boolean' ||
    typeof paused !== 'boolean' ||
    typeof presaleMintCount !== 'number' ||
    typeof presaleTokenPerAddressLimit !== 'number' ||
    typeof presaleTotalSupply !== 'number' ||
    typeof presaleMaxSupply !== 'number'
  ) {
    loading = true;
  }
  if (account) {
    connected = true;
    if (account === owner) {
      isOwner = true;
    }
  }
  if (!loading && totalSupply === maxSupply) {
    soldOut = true;
  }
  return (
    <Container
      maxWidth="xl"
      sx={{
        minHeight: '100vh',
        backgroundColor: '#FAE468',
      }}
    >
      <Header isOwner={isOwner} />
      <Container
        sx={{
          padding: 2,
        }}
      >
        <Grid container spacing={6}>
          <Grid item md={12} lg={4} sx={{ textAlign: 'center' }}>
            <Box
              component="img"
              src={TrippySailorsMintSample}
              sx={{
                borderRadius: 8,
                width: {
                  xs: '150px',
                  sm: '180px',
                  md: '190px',
                  lg: '273px',
                  xl: '273px',
                },
              }}
            />
          </Grid>
          <Grid item md={12} lg={8}>
            {!isCorrectNetwork && <SwitchNetwork />}
            {isCorrectNetwork && !connected && (
              <PreConnect totalSupply={totalSupply} maxSupply={maxSupply} />
            )}
            {isCorrectNetwork && connected && loading && <Loader />}
            {isCorrectNetwork && connected && !loading && soldOut && (
              <SoldOut />
            )}
            {isCorrectNetwork &&
              connected &&
              !loading &&
              !soldOut &&
              paused && <Paused />}
            {isCorrectNetwork &&
              connected &&
              !loading &&
              !soldOut &&
              !paused &&
              connected && (
                <Sale
                  totalSupply={totalSupply}
                  maxSupply={maxSupply}
                  cost={cost}
                  presale={presale}
                  presaleCost={presaleCost}
                  etherBalance={etherBalance}
                  maxMintInWallet={maxMintInWallet}
                  isApprovedForPresale={isApprovedForPresale}
                  presaleMintCount={presaleMintCount}
                  presaleTokenPerAddressLimit={presaleTokenPerAddressLimit}
                  presaleTotalSupply={presaleTotalSupply}
                  presaleMaxSupply={presaleMaxSupply}
                />
              )}
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};
