import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { blue } from '@mui/material/colors';
import Grid from '@mui/material/Grid';

export const Item = (props) => {
  const { icon, title, subtitles } = props;
  const subtitleElement = subtitles.map((subtitle, index) => (
    <div key={index}>
      {subtitle}
      <br />
    </div>
  ));
  return (
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <Paper
        sx={{ padding: '32px', borderRadius: '25px', minHeight: '180px' }}
        elevation={0}
      >
        <Stack spacing={1} alignItems="center" justifyContent="center">
          <Avatar sx={{ bgcolor: blue[500] }}>
            <Box
              component="img"
              src={icon}
              sx={{ width: '24px', height: '24px' }}
            />
          </Avatar>

          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {title}
          </Typography>

          <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
            {subtitleElement}
          </Typography>
        </Stack>
      </Paper>
    </Grid>
  );
};

Item.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitles: PropTypes.arrayOf(PropTypes.string),
};
