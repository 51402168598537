import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/Button';
import AppLogo from './../../assets/images/app-logo.png';
import { Link } from 'react-router-dom';
import ButtonBase from '@mui/material/ButtonBase';
import { WalletConnect } from './../WalletConnect';
import SettingsIcon from '@mui/icons-material/Settings';
import { adminPanel } from '../../contract';

export const Header = ({ isOwner }) => {
  return (
    <Box sx={{ flexGrow: 1, marginBottom: 4 }}>
      <AppBar
        position="static"
        sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}
      >
        <Toolbar>
          <ButtonBase component={Link} to="/">
            <Box
              component="img"
              src={AppLogo}
              sx={{
                width: {
                  xs: '80px',
                  s: '80px',
                  md: '140px',
                  lg: '140px',
                  xl: '140px',
                },
                cursor: 'pointer',
                marginRight: 2,
              }}
            />
          </ButtonBase>
          <Typography
            variant="h5"
            component="div"
            sx={{
              flexGrow: 1,
              padding: 2,
              color: 'black',
              fontWeight: '700',
              display: {
                sm: 'none',
                xs: 'none',
                md: 'block',
                lg: 'block',
                xl: 'block',
              },
            }}
          >
            Trippy Sailors Minter
          </Typography>
          <Box sx={{ flexGrow: 1, textAlign: 'right' }} component="div">
            <WalletConnect hideOnDisconnected={true} />
          </Box>
          {isOwner && (
            <IconButton sx={{ marginLeft: 2 }} href={adminPanel}>
              <SettingsIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};
