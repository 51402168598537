import * as React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { PriceStats } from './PriceStats';
import { QuantitySelector } from './QuantitySelector';
import { useContractFunction } from '@usedapp/core';
import { contract } from './../../contract';

const presaleTitle = 'Trippy Sailors Presale Mint ⛵';
const mainSaleTitle = 'Mint Your Trippy Sailor NFT ⛵';
const mainSaleSubtitle =
  'Mint your Access Pass to the Trippy Sailors Adventure Club! A unique travel club where NFTs unlock real sailing trips to the Caribbean seas, travel discounts and participation in our upcoming SailBNB DAO.';

export const Sale = ({
  totalSupply,
  maxSupply,
  cost,
  presale: contractPresale,
  presaleCost,
  etherBalance,
  maxMintInWallet,
  isApprovedForPresale,
  presaleMintCount,
  presaleTokenPerAddressLimit,
  presaleTotalSupply,
  presaleMaxSupply,
}) => {
  const presaleSubtitle = `Join the adventure club! 1000 NFTs are available for our early supporters at a discounted rate. You can mint upto ${presaleTokenPerAddressLimit} NFTs at presale rate per address. A unique travel club where NFTs unlock real sailing trips to the Caribbean seas, travel discounts and participation in our upcoming SailBNB DAO.`;
  const { state, send, resetState } = useContractFunction(contract, 'mint', {
    transactionName: 'mint',
  });
  const nftsLeft = maxSupply - totalSupply;
  let maxNftsAllowedToMint = Math.min(nftsLeft, maxMintInWallet);
  let presale = false;
  if (
    contractPresale &&
    isApprovedForPresale &&
    presaleTotalSupply < presaleMaxSupply &&
    presaleMintCount < presaleTokenPerAddressLimit
  ) {
    presale = true;
    const presaleNftsLeft = presaleMaxSupply - presaleTotalSupply;
    const presaleMintsLeft = presaleTokenPerAddressLimit - presaleMintCount;
    maxNftsAllowedToMint = Math.min(
      nftsLeft,
      presaleNftsLeft,
      maxMintInWallet,
      presaleMintsLeft
    );
  }
  const title = presale ? presaleTitle : mainSaleTitle;
  const subtitle = presale ? presaleSubtitle : mainSaleSubtitle;
  const onMint = (amount, value) => {
    send(amount, { value: value });
  };
  return (
    <Stack alignItems="center" justifyContent="center" spacing={2}>
      <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
        {title}
      </Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
        {subtitle}
      </Typography>
      <QuantitySelector
        presale={presale}
        etherBalance={etherBalance}
        maxMintInWallet={maxNftsAllowedToMint}
        cost={cost}
        presaleCost={presaleCost}
        onMint={onMint}
        state={state}
        resetState={resetState}
      />
      <PriceStats
        totalSupply={totalSupply}
        maxSupply={maxSupply}
        presaleTotalSupply={presaleTotalSupply}
        presaleMaxSupply={presaleMaxSupply}
        cost={cost}
        presale={presale}
        presaleCost={presaleCost}
      />
    </Stack>
  );
};
