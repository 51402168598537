import React, { useState, createRef, useEffect } from 'react';
import { utils } from 'ethers';
import { BigNumber } from 'ethers';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import NumericInput from 'react-numeric-input';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { MintStatus } from './MintStatus';
import { ReactComponent as OpenseaIcon } from './../../assets/images/opensea.svg';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import { openseaLink } from './../../contract';
import Tooltip from '@mui/material/Tooltip';

export const QuantitySelector = ({
  presale,
  etherBalance,
  maxMintInWallet,
  cost,
  presaleCost,
  onMint,
  state,
  resetState,
}) => {
  const numberInputRef = createRef();
  const { status } = state;
  const inProgress = status !== 'None';
  const currentCost = presale ? presaleCost : cost;
  const title = presale ? 'Presale Mint' : 'Main Sale';
  const [amount, setAmount] = useState(1);
  const formattedBalance = parseFloat(utils.formatEther(etherBalance)).toFixed(
    4
  );
  const currentTotal = BigNumber.from(amount).mul(currentCost);
  const formattedTotal = parseFloat(utils.formatEther(currentTotal)).toFixed(4);
  const enoughBalance = currentTotal.lte(etherBalance);
  useEffect(() => {
    numberInputRef.current.refsInput.disabled = 'disabled';
  }, [numberInputRef]);
  const isMax = maxMintInWallet === amount;
  return (
    <Card
      sx={{
        borderRadius: 4,
        minWidth: { xs: 280, sm: 300, md: 360, lg: 360, xl: 360 },
      }}
    >
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5" component="div">
            {title}
          </Typography>
          <Tooltip title="View Collection On OpenSea">
            <IconButton aria-label="OpenSea" href={openseaLink} target="_blank">
              <SvgIcon component={OpenseaIcon} inheritViewBox />
            </IconButton>
          </Tooltip>
        </Stack>
        <List>
          <ListItem
            secondaryAction={<Typography>{formattedBalance} ETH</Typography>}
          >
            <ListItemText primary="Balance" />
          </ListItem>
          <Divider component="li" />
          <ListItem
            secondaryAction={
              <Stack direction="row" spacing={1}>
                {isMax && (
                  <Chip
                    label="max"
                    variant="outlined"
                    color="info"
                    size="small"
                  />
                )}
                <Box
                  component="div"
                  sx={{
                    '& input': {
                      width: '100px',
                      pointerEvents: 'none !important',
                    },
                  }}
                >
                  <NumericInput
                    min={1}
                    max={maxMintInWallet}
                    value={amount}
                    mobile
                    onChange={(valueAsNumber) => setAmount(valueAsNumber)}
                    tabIndex="-1"
                    ref={numberInputRef}
                    disabled={inProgress}
                  />
                </Box>
              </Stack>
            }
          >
            <ListItemText primary="Amount" />
          </ListItem>
          <Divider component="li" />
          <ListItem
            secondaryAction={<Typography>{formattedTotal} ETH</Typography>}
          >
            <ListItemText primary="Total" />
          </ListItem>
        </List>
        {inProgress && (
          <MintStatus
            state={state}
            resetState={() => {
              setAmount(1);
              resetState();
            }}
          />
        )}
        {!inProgress && !enoughBalance && (
          <Alert severity="error">
            Balance not enough to buy {amount} NFT{amount > 1 ? 's' : ''}
          </Alert>
        )}
      </CardContent>
      <CardActions sx={{ justifyContent: 'center' }}>
        {!inProgress && enoughBalance && (
          <Button
            size="medium"
            variant="outlined"
            disabled={!enoughBalance}
            onClick={() => {
              onMint(amount, currentTotal);
            }}
          >
            Mint
          </Button>
        )}
      </CardActions>
    </Card>
  );
};
