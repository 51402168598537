import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import AppLogo from './../../assets/images/app-logo.png';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as DiscordIcon } from './../../assets/images/discord.svg';
import { ReactComponent as TwitterIcon } from './../../assets/images/twitter.svg';
import { ReactComponent as InstagramIcon } from './../../assets/images/instagram.svg';
import { ReactComponent as MediumIcon } from './../../assets/images/medium.svg';
import SvgIcon from '@mui/material/SvgIcon';

export const Footer = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        overflow: 'hidden',
        paddingTop: '72px',
        paddingBottom: '72px',
        background: '#95F1FC',
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              component="img"
              src={AppLogo}
              sx={{
                width: '173px',
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              direction={{ sm: 'row', xs: 'column' }}
              spacing={4}
              justifyContent="center"
              alignItems="center"
            >
              <Link
                href="#collection"
                underline="none"
                color="black"
                variant="body1"
                sx={{ fontWeight: 'bold' }}
              >
                The Collection
              </Link>
              <Link
                href="#perks"
                underline="none"
                color="black"
                variant="body1"
                sx={{ fontWeight: 'bold' }}
              >
                TSAC
              </Link>
              <Link
                href="#dao"
                underline="none"
                color="black"
                variant="body1"
                sx={{ fontWeight: 'bold' }}
              >
                SailBNB
              </Link>
              <Link
                href="#roadmap"
                underline="none"
                color="black"
                variant="body1"
                sx={{ fontWeight: 'bold' }}
              >
                Roadmap
              </Link>
              <Link
                href="#team"
                underline="none"
                color="black"
                variant="body1"
                sx={{ fontWeight: 'bold' }}
              >
                Team
              </Link>
              <Link
                href="/mint"
                underline="none"
                color="black"
                variant="body1"
                sx={{ fontWeight: 'bold' }}
              >
                Mint
              </Link>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack direction="row" spacing={2} justifyContent="center">
              <IconButton
                aria-label="twitter"
                href="https://twitter.com/trippysailors"
                target="_blank"
              >
                <TwitterIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                aria-label="discord"
                href="https://discord.gg/kgd5murXwA"
                target="_blank"
              >
                <SvgIcon component={DiscordIcon} inheritViewBox />
              </IconButton>
              <IconButton
                aria-label="instagram"
                href="https://www.instagram.com/trippysailors/"
                target="_blank"
              >
                <InstagramIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                aria-label="medium"
                href="https://trippysailors.medium.com/"
                target="_blank"
              >
                <MediumIcon fontSize="inherit" />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
