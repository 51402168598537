import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Item } from "./Item";
import Gun from "./../../assets/images/gun.png";
import Cash from "./../../assets/images/cash.png";
import Pirate from "./../../assets/images/pirate.png";
import Map from "./../../assets/images/map.png";

export const Roadmap = () => {
  return (
    <Container
      id="roadmap"
      maxWidth="xl"
      sx={{
        overflowY: "visible",
        overflowX: "clip",
        paddingTop: "72px",
        paddingBottom: "72px",
        position: "relative",
        background: "#81CAFA",
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ flexGrow: 1 }}>
          <Stack
            spacing={8}
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              What's On Our Roadmap?
            </Typography>
          </Stack>
          <Grid
            container
            alignItems="stretch"
            spacing="32"
            sx={{ marginTop: "32px" }}
          >
            <Item
              icon={Gun}
              title="Q1 2023"
              subtitles={[
                "Launch community activations",
                "Begin pre-sale registrations",
              ]}
            />
            <Item
              icon={Cash}
              title="Q2 2023"
              subtitles={[
                "Launch pre-sale and main mint",
                "Collabs with Web3 communities",
              ]}
            />
            <Item
              icon={Pirate}
              title="H2 2023"
              subtitles={[
                "Close mint and begin sweepstakes",
                "Launch the TSAC merch shop",
              ]}
            />
            <Item
              icon={Cash}
              title="2024"
              subtitles={[
                "Secure the TSAC catamaran",
                "Trippy sailors sailing trips begin",
              ]}
            />
            <Item
              icon={Pirate}
              title="2024-2025"
              subtitles={["TSAC adventures unroll", "Launch of SailBNB DAO"]}
            />
            <Item
              icon={Pirate}
              title="2026-2028"
              subtitles={[
                "More TSAC Sailing Trips and Events",
                "Expand SailBNB DAO to 4 continents",
              ]}
            />
          </Grid>
        </Box>
      </Container>
      <Box
        component="img"
        src={Map}
        sx={{
          width: {
            xs: "50px",
            sm: "80px",
            md: "130px",
            lg: "140px",
            xl: "180px",
          },
          position: "absolute",
          left: 0,
          top: {
            xs: -35,
            sm: -50,
            md: -75,
            lg: -80,
            xl: -100,
          },
        }}
      />
    </Container>
  );
};
