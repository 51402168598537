import React from 'react';
import Container from '@mui/material/Container';
import IframeResizer from 'iframe-resizer-react';
import { Header } from './Header';
import { adminPanel } from './../../contract';

export const Admin = () => {
  return (
    <Container maxWidth="xl" sx={{ minHeight: '100vh' }}>
      <Header />
      <IframeResizer
        heightCalculationMethod="lowestElement"
        src={adminPanel}
        style={{
          width: '1px',
          minWidth: '100%',
          minHeight: 'calc(100vh - 200px)',
        }}
        resizeFrom="child"
      />
    </Container>
  );
};
