import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

export const Paused = () => {
  return (
    <Stack alignItems="center" justifyContent="center" spacing={4}>
      <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
        Trippy Sailor NFTs Will Be Available Soon ⛵
      </Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
        This is your access pass to a unique travel club where NFTs unlock real
        sailing trips to the Caribbean seas, travel discounts and participation
        in our upcoming SailBNB DAO.
      </Typography>
    </Stack>
  );
};
