import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

export const Stat = ({ title, subtitle, compact, subtitleCrossed }) => {
  const variant = compact ? 'subtitle1' : 'h5';
  const crossed = (
    <Box
      component="span"
      sx={{ textDecoration: 'line-through', paddingRight: 1 }}
    >
      {subtitleCrossed}
    </Box>
  );
  return (
    <Paper
      sx={{
        padding: '8px',
        borderRadius: '25px',
        minHeight: '80px',
      }}
      elevation={0}
    >
      <Stack spacing={1} alignItems="center" justifyContent="center">
        <Typography
          variant={variant}
          sx={{ fontWeight: 'bold', fontSize: { xs: 'small' } }}
        >
          {title}
        </Typography>

        <Typography
          variant="subtitle1"
          sx={{ textAlign: 'center', fontSize: { xs: 'small' } }}
        >
          {subtitleCrossed && crossed}
          {subtitle}
        </Typography>
      </Stack>
    </Paper>
  );
};
