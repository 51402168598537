import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppLogo from './../../assets/images/app-logo.png';
import { Link } from 'react-router-dom';
import ButtonBase from '@mui/material/ButtonBase';

export const Header = () => {
  return (
    <Box sx={{ flexGrow: 1, marginBottom: 4 }}>
      <AppBar
        position="static"
        sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}
      >
        <Toolbar>
          <ButtonBase component={Link} to="/">
            <Box
              component="img"
              src={AppLogo}
              sx={{
                width: '140px',
                cursor: 'pointer',
              }}
            />
          </ButtonBase>
          <Typography
            variant="h5"
            component="div"
            sx={{
              flexGrow: 1,
              padding: 2,
              color: 'black',
              fontWeight: '700',
            }}
          >
            Trippy Sailors Admin Panel
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
