import React from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { CircularProgress } from '@mui/material';

export const MintStatus = ({ state, resetState }) => {
  const { status } = state;
  let element = <></>;
  if (status === 'Success') {
    element = (
      <>
        <Alert severity="success">🎉 Congrats! Your mint was successful</Alert>
        <Button variant="outlined" onClick={resetState}>
          Mint More
        </Button>
      </>
    );
  } else if (status === 'PendingSignature') {
    element = (
      <>
        <Alert severity="warning">Waiting for transaction to be signed</Alert>
      </>
    );
  } else if (status === 'Mining') {
    element = (
      <>
        <CircularProgress />
        <Alert severity="info">Waiting for transaction to be mined</Alert>
      </>
    );
  } else if (status === 'Fail') {
    element = (
      <>
        <Alert severity="error">Transaction Failed</Alert>
        <Button variant="outlined" onClick={resetState}>
          Try Again
        </Button>
      </>
    );
  } else if (status === 'Exception') {
    element = (
      <>
        <Alert severity="error">Transaction Was Not Submitted</Alert>
        <Button variant="outlined" onClick={resetState}>
          Try Again
        </Button>
      </>
    );
  }
  return (
    <Stack
      direction="column"
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      {element}
    </Stack>
  );
};
