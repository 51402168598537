import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SailorHat from './../../assets/images/sailor-hat.png';
import Telescope from './../../assets/images/telescope.png';

export const Perks = () => {
  return (
    <Container
      id="perks"
      maxWidth="xl"
      sx={{
        overflowX: 'clip',
        overflowY: 'visible',
        paddingTop: '72px',
        paddingBottom: '72px',
        position: 'relative',
        background: '#FAE468',
      }}
    >
      <Box
        component="img"
        src={Telescope}
        sx={{
          width: {
            xs: '50px',
            sm: '80px',
            md: '190px',
            lg: '264px',
            xl: '264px',
          },
          position: 'absolute',
          left: 0,
          top: {
            xs: -30,
            sm: -50,
            md: -130,
            lg: -200,
            xl: -200,
          },
        }}
      />
      <Container maxWidth="lg">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Stack spacing={4} alignItems="flex-start">
                <Typography variant="h3" sx={{ fontWeight: 900 }}>
                  Perks of the Trippy Sailors Adventure Club
                </Typography>

                <Typography variant="h6">
                  Looking for exciting ways to escape the crypto winter? Grab a
                  Trippy Sailor NFT!
                  <br />
                  <br />
                  We are a community of fun loving, Web3 frens, looking to meet
                  new people around the world and have a great time doing it.
                </Typography>
                <Box
                  component="img"
                  src={SailorHat}
                  sx={{
                    width: {
                      xs: '150px',
                      sm: '180px',
                      md: '190px',
                      lg: '290px',
                      xl: '290px',
                    },
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing="32">
                <Grid item xs={12} md={6}>
                  <Stack spacing={1} alignItems="flex-start">
                    <Typography variant="h5" sx={{ fontWeight: 900 }}>
                      Vacation Utility for Holders ⛵︎
                    </Typography>

                    <Typography variant="subtitle1">
                      555 out of the 5555 TSAC NFT holders can qualify for free
                      trip on a luxurious sailing catamaran by entering in the
                      weekly vacation raffle. The happy vacationers will get to
                      explore treasure islands sailing in the warm Caribbean
                      seas.
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1} alignItems="flex-start">
                    <Typography variant="h5" sx={{ fontWeight: 800 }}>
                      Legendary Sailors NFTs ⚓︎
                    </Typography>

                    <Typography variant="subtitle1">
                      There are 55 Legendary Sailors NFTs hidden within the
                      collection. Holders of these Legendary Sailors will
                      automatically qualify for a trip. And of course, they can
                      also bring a plus one to share the cabin. Will you be a
                      lucky sailor?
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1} alignItems="flex-start">
                    <Typography variant="h5" sx={{ fontWeight: 900 }}>
                      Ocean Plastic Clean Up 🐠
                    </Typography>

                    <Typography variant="subtitle1">
                      A percentage of the NFT sale goes towards the cleaning of
                      our lovely beaches and oceans with the help of local
                      communities and foundations.
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1} alignItems="flex-start">
                    <Typography variant="h5" sx={{ fontWeight: 900 }}>
                      Fun With Trippy Frens 🧜‍♀️
                    </Typography>

                    <Typography variant="subtitle1">
                      We will create unique events and experiences with other
                      NFT communities aboard the Trippy Sailors Catamaran as
                      well as in the Metaverse.
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Container>
  );
};
