import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ImageRow } from './ImageRow';
import TreasureChest from './../../assets/images/treasure-chest.png';
import CaptainHat from './../../assets/images/captain-hat.png';
import Fab from '@mui/material/Fab';
import { ReactComponent as DiscordIcon } from './../../assets/images/discord-white.svg';
import SvgIcon from '@mui/material/SvgIcon';

export const Collection = () => {
  return (
    <Container
      id="collection"
      maxWidth="xl"
      sx={{
        overflow: 'hidden',
        paddingTop: '72px',
        paddingBottom: '72px',
        position: 'relative',
        background: '#F7FEFF',
      }}
    >
      <Stack spacing={4} alignItems="center">
        <Typography
          variant="h3"
          sx={{ fontWeight: 'bold', textAlign: 'center' }}
        >
          Setting sail on epic adventures
        </Typography>
        <Typography variant="h6" sx={{ maxWidth: 'md', textAlign: 'center' }}>
          The Trippy Sailors Adventure Club gives holders{' '}
          <Typography component="span" variant="h6" sx={{ fontWeight: 'bold' }}>
            opportunities to travel on sailing trips in the Caribbean Seas
          </Typography>{' '}
          on a state-of-the-art catamaran, connect with other thrill-seekers
          from around the world, and access future perks like the SailBNB DAO.
        </Typography>
        <Fab
          href="https://discord.gg/kgd5murXwA"
          target="_blank"
          color="error"
          variant="extended"
          sx={{ minWidth: '360px' }}
        >
          <SvgIcon
            component={DiscordIcon}
            inheritViewBox
            sx={{ marginRight: 1 }}
          />{' '}
          Join Our Community
        </Fab>
        <ImageRow />
        <Typography
          variant="h3"
          sx={{ fontWeight: 'bold', textAlign: 'center' }}
        >
          Who Can Qualify For A Sailing Trip?
        </Typography>
        <Typography variant="h6" sx={{ maxWidth: 'md', textAlign: 'center' }}>
          All 5555 NFTs in the collection need to be minted to unlock this
          milestone. A total of 555 Trippy Sailors and their plus one will get
          to experience the sailing trip. They will be selected on a weekly
          basis through contests, community engagement and challenges. Ultra
          rare NFTs called Legendary Sailors will qualify automatically.
        </Typography>
      </Stack>
      <Box
        component="img"
        src={TreasureChest}
        sx={{
          width: {
            xs: '50px',
            sm: '80px',
            md: '90px',
            lg: '162px',
            xl: '162px',
          },
          position: 'absolute',
          left: 0,
          top: 10,
        }}
      />
      <Box
        component="img"
        src={CaptainHat}
        sx={{
          width: {
            xs: '50px',
            sm: '80px',
            md: '90px',
            lg: '173px',
            xl: '173px',
          },
          position: 'absolute',
          right: 0,
          top: -10,
        }}
      />
    </Container>
  );
};
