import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

export const SoldOut = () => {
  return (
    <Stack alignItems="center" justifyContent="center" spacing={4}>
      <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
        All Trippy Sailor NFTs Have Been Sold Out ⛵
      </Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
        Join the adventure club! Buy a Trippy Sailor NFT for a chance to win a
        real sailing trip in the Caribbean Seas for you and your partner.
      </Typography>
    </Stack>
  );
};
